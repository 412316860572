import React from 'react'
import { css } from 'theme-ui'
import { Card, Text, Heading, Box, Button } from 'theme-ui'
import NewsletterForm from '@components/NewsletterForm'
// import useMailChimp from '@helpers/useMailChimp'
import { FaChartLine, FaBullseye } from 'react-icons/fa'
import { Link } from 'gatsby'

const styles = {
  card: {
    position: `relative`
  },
  wrapper: {
    maxWidth: 500,
    textAlign: `center`,
    mx: `auto`,
    py: 3
  },
  icons: {
    display: [`none`, `none`, `block`],
    position: `absolute`,
    top: `1rem`,
    left: `5rem`,
    svg: {
      // display: `block`
    }
  },
  chart: {
    fontSize: `10rem`,
    color: `omegaDarker`,
    transform: `rotate(-10deg)`,
    ml: `-10rem`
    // mt: '4em'
  },
  bullseye: {
    fontSize: `12rem`,
    color: `omegaLighter`,
    // transform: `rotate(120deg)`,
    pt: `2rem`
    // ml: `0rem`
  },
  form: {
    maxWidth: 400,
    mx: `auto`,
    mt: 4,
    a: {
      ml: '1rem'
    }
  }
}

const SubscriptionsCTA = ({ simple }) => {
  return (
    <Card variant='paper' sx={styles.card}>
      <Box sx={styles.wrapper}>
        {!simple && (
          <Box sx={styles.icons}>
            <FaBullseye css={css(styles.bullseye)} />
            <FaChartLine css={css(styles.chart)} />
          </Box>
        )}
        <Heading variant='h2'>{/*Start Your Free Trial*/}Get Started Today</Heading>
        <Text variant='p'>
          {/*Start your free trial today and supercharge your subscription
          orders.*/}
          Get started for free and discover why your customers are really churning and learn how to fix it.
        </Text>
        <Box sx={styles.form}>
          <Button
            as={Link}
            to='https://subscriptions.viadog.com/register'
            target='_blank'
            variant='primary'
          >
            Get Started
          </Button>
          <Button
            variant='white'
            as={Link}
            to='https://schedule.viadog.com'
            target='_blank'
          >
            Book a Demo
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

SubscriptionsCTA.defaultProps = {
  simple: false
}

export default SubscriptionsCTA
